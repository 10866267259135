<template>
    <div>
        <div v-for="no in getNumberRange(from, to)" :key="no" class="cheque print-only" dir="rtl">
            <img src="@/assets/images/invoice.png" class="image" />
            <qr-code class="qr" size="90" :text="no"></qr-code>
            <div class="orderNo">
                <input dir="ltr" style="text-align: left" :value="no.toString()" readonly />
            </div>
            <div class="created">
                <input readonly />
            </div>
            <div class="merchantName">
                <input readonly />
            </div>
            <div class="customerName">
                <input readonly />
            </div>
            <div class="customerPhonenumber" dir="ltr">
                <input readonly />
            </div>
            <div class="address">
                <input readonly />
            </div>
            <div class="packageContent">
                <input readonly />
            </div>
            <div class="packageItemsCount">
                <input readonly />
            </div>
            <div class="packageCost">
                <input :value="' د.ع '" readonly />
            </div>


            <div class="merchantPhonenumber" dir="ltr">
                <input readonly />
            </div>


        </div>
    </div>
</template>
    
<script>
export default {

    data() {
        return {
            from: 0,
            to: 0,
        }
    },

    created() {
        this.getItem()
        setTimeout(() => {
            window.print()
        }, 5000);
    },

    methods: {
        getNumberRange(start, end) {
            return Array.from({ length: end - start + 1 }, (_, index) => start + index);
        },
        async getItem() {
            this.loading = true
            try {
                var range = this.$route.params.range.split('-')
                if (range.length > 1) {
                    this.from = parseInt(range[0]);
                    this.to = parseInt(range[1]);
                }
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
    
<style scoped>
html,
body {
    margin: 0;
    padding: 0;
}

.image {
    display: block;
    width: 100%;
    height: auto;
}

.qr {
    position: absolute;
    top: 11.3cm;
    left: 1.6cm;
    font-size: 13px;
    font-weight: bold;
}

.cheque {
    width: 8.27in;
    margin: 0 auto;
    position: relative;
}

.orderNo {
    position: absolute;
    top: 2.3cm;
    left: 3cm;
    font-size: 16px;
    font-weight: bold;
}

.merchantName {
    position: absolute;
    top: 3.52cm;
    left: 11cm;
    font-size: 16px;
    font-weight: bold;
}

.merchantPhonenumber {
    position: absolute;
    top: 3.52cm;
    left: 0.3cm;
    font-size: 16px;
    font-weight: bold;
}

.customerName {
    position: absolute;
    top: 4.7cm;
    left: 11cm;
    font-size: 16px;
    font-weight: bold;
}

.customerPhonenumber {
    position: absolute;
    top: 4.7cm;
    left: 0.3cm;
    font-size: 16px;
    font-weight: bold;
}

.address {
    position: absolute;
    top: 5.8cm;
    left: 11cm;
    font-size: 16px;
    font-weight: bold;
}

.packageContent {
    position: absolute;
    top: 7cm;
    left: 11cm;
    font-size: 16px;
    font-weight: bold;
}

.packageItemsCount {
    position: absolute;
    top: 7cm;
    left: 0.3cm;
    font-size: 16px;
    font-weight: bold;
}

.packageCost {
    position: absolute;
    top: 8.2cm;
    left: 11cm;
    font-size: 16px;
    font-weight: bold;
}

.created {
    position: absolute;
    top: 1.8cm;
    left: 13.5cm;
    font-size: 16px;
    font-weight: bold;
}
</style>
    